import React, { useState } from "react";
import "./booking.css";
import { Form, FormGroup, ListGroup, ListGroupItem, Button } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Port } from "../../Port";

const Booking = ({ tourid, tourscheduleid, tour }) => {
  const logindata = useSelector((state) => state.auth.login);
  const navigate = useNavigate();
  const { price } = tour;
  const [credentials, setCredentials] = useState({
    tourseats: tourid,
    user: logindata._id,
    tour: tourscheduleid,
    numOfPersons: 0, 
    totalCharges: 0,
    kid: 0,
    elder: 0,
    number: '',
    fullname: '',
  });
  const serviceFeePerPerson = 20;
  // Update fields and recalculate totalCharges and numOfPersons
  const handleChange = (e) => {
    const { id, value } = e.target;
    // Handle validation only for 'elder' and 'elder'
    if (id === 'elder') {
      const updatedValue = parseInt(value, 10);
  
      // Ensure values are greater than or equal to 0
      if (updatedValue < 0) {
        toast.error(`${id} must be greater than or equal to 0`);
        return;
      }
  
      setCredentials((prev) => {
        const updatedCredentials = { ...prev, [id]: updatedValue };
        // Recalculate numOfPersons and total charges for elders and elders
        const newTotalPersons =  updatedCredentials.elder;
        const totalServiceFee = newTotalPersons * serviceFeePerPerson;
        updatedCredentials.numOfPersons = newTotalPersons; // Only elders and elders
        updatedCredentials.totalCharges = newTotalPersons * price + totalServiceFee; // Calculate total charges
  
        return updatedCredentials;
      });
    } else {
      // Update other fields normally (without numeric validation)
      setCredentials((prev) => ({
        ...prev,
        [id]: value,
      }));
    }
  };
  
  const handleClick = async (e) => {
  
    e.preventDefault();
    if(logindata==='' || logindata.active===false || logindata._id==undefined)
      {
        navigate('/user/login');
      }
        
    // Check for phone number validation
    const pakistanPhoneRegex = /^(03[0-9]{9})$/;
   
    if (!pakistanPhoneRegex.test(credentials.number)) {
      toast.error("Please enter a valid Pakistani phone number starting with 03 && least 11 digits");
      return;
    }
    try {
      const response = await axios.post(`${Port}/user/booking/create`, credentials, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      // Check for available seats
      if (response.data.availableSeats < credentials.numOfPersons) {
        toast.error(`You can only book up to ${response.data.availableSeats} seats.`);
        return;
      }
      toast.success(`${response.data.message}. Available seats left: ${response.data.availableSeats}`);
      navigate("/thank-you");
    }
     catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong. Please try again.");
    }
  };
  
  return (
    <>
      <ToastContainer />
      <div className="booking">
        <div className="booking__form">
          <h5>Booking Information</h5>
          <Form className="booking__info-form" onSubmit={handleClick}>
            <FormGroup>
              <input
                type="text"
                placeholder="Full Name"
                id="fullname"
                required
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup className="">
              <input
                placeholder="Contact Number"
                id="number"
                required
                onChange={handleChange}
              />
            </FormGroup>

        
            <FormGroup>
              <input
                type="number"
                placeholder="No. of Children < 3 yrs (free)"
                id="kid"
                onChange={handleChange}
              />
            </FormGroup>


            <FormGroup>
              <input
                type="number"
                placeholder="No of Adults"
                id="elder"
            
                onChange={handleChange}
              />
            </FormGroup>

            <Button className="btn primary__btn w-100 mt-4" type="submit">
              Book Now
            </Button>
          </Form>
        </div>

        <div className="booking__bottom">
          <ListGroup>
            <ListGroupItem className="border-0 pc-0">
              <h5 className="d-flex align-items-center gap-1">
                Rs {price} <i className="ri-close-line"></i> {(credentials.elder || 0) } adults
              </h5>
              <span> Rs {credentials.elder * price || 0} </span>
            </ListGroupItem>

          

            <ListGroupItem className="total border-0 pc-0">
              <h5>Total</h5>
              <span> Rs {credentials.totalCharges || 0} </span>
            </ListGroupItem>
          </ListGroup>
        </div>
      </div>
    </>
  );
};

export default Booking;
