import React, { useEffect, useState } from "react";
import "../styles/home.css";
import { Container, Row, Col } from "reactstrap";
import Subtitle from "../shared/Subtitle";
import worldImg from "../assets/images/world.png";
import experienceImg from "../assets/images/experience.png";
import ServiceList from "../services/ServiceList";
import FeaturedTourList from "../components/Featured-tours/FeaturedTourList";
import MasonryImagesGallery from "../components/Image-gallery/MasonryImagesGallery";
import Testimonials from "../components/Testimonial/Testimonials";
import Newsletter from "../shared/Newsletter";
import SearchComponent from '../components/SearchComponent/SearchComponent'
import galleryImage1 from '../assets/images/hero-img01.jpg';
import galleryImage2 from '../assets/images/hero-img02.jpg';
import axios from "axios";
import { Port } from "../Port";
import SEO from "../SEO";

const Home = () => {
  const [stats, setStats] = useState(null);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  // const [mediaData, setMediaData] = useState([]);

  const fetchStatistics = async () => {
    try {
      const response = await axios.get(
        `${Port}/milestone/get`
      );
      if (response.data.length > 0) {
        setStats(response.data[0]); 
      }
    } catch (error) {
      setError("Error fetching statistics data.");
    }
  };

  const fetchTourOperator = async () => {
    try {
      const response = await axios.get(
        `${Port}/metadata/get`
      );
      if (response.data && response.data.length > 0) {
        setDescription(
          response.data[0].description || "No description available."
        );
      } else {
        setDescription("No description available.");
      }
    } catch (err) {
      setError("Error fetching tour operator data.");
    }
  };

  // const fetchMedia = async () => {
  //   try {
  //     const response = await axios.get(`${Port}/media/get`); 
  //     setMediaData(response.data);
  //     setLoading(false);
  //   } catch (err) {
  //     setError(err.message);
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      fetchStatistics();
      fetchTourOperator();
      // fetchMedia();
      setLoading(false);
    };

    fetchData(); 

  }, []);

  if (loading) {
    return <div className="d-flex justify-content-center align-items-center vh-100 fw-bolder text-warning">
    Loading...
  </div>
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
    <SEO
        title="Home-Tripwaly"
        description="Welcome to the homepage of My Website"
        keywords="home, my website, seo keywords"
      url="home/dashboard"
      />
     
      <section>
        <Container>
          <Row>
            <Col lg="6" md="8" sm="12">
              <div className="hero__content">
                <div className="hero__subtitle d-flex align-items-center">
                  <Subtitle subtitle={"Your journey, your story!"} />
                  <img src={worldImg} alt="" />
                </div>

                <h1>
                  Make every trip a <span className="highlight">story</span>{" "}
                  worth sharing
                </h1>
                <p>{description}</p>
              </div>
            </Col>
            
              <React.Fragment>
                <Col lg="2" md="4" sm="6">
                  <div className="hero__img-box">
                    <img src={galleryImage1} alt="" />
                  </div>
                </Col>
                <Col lg="2" md="4" sm="6">
                  <div className="hero__img-box mt-4">
                  <video src="hero-video.mp4" loop autoplay muted controls />
                  </div>
                </Col>
                <Col lg="2" md="4" sm="6">
                  <div className="hero__img-box mt-5">
                    <img src={galleryImage2} alt="" />
                  </div>
                </Col>
              </React.Fragment>
            
          </Row>
        </Container>
      </section>
    <SearchComponent/>
      <section>
        <Container>
          <Row>
            <Col lg="3" md="4">
              <Subtitle subtitle={"What we serve"} />
              <h2 className="services__title">We offer the best services</h2>
            </Col>
            <ServiceList />
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5">
              <Subtitle subtitle={"Explore"} />
              <h2 className="featured__tour-title">Our featured tours</h2>
            </Col>
            <FeaturedTourList />
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg="6" md="12">
              <div className="experience__content">
                <Subtitle subtitle={"Experience"} />
                <h2>
                  With years of expertise <br /> we will serve you
                </h2>
                <p>{stats?.message}</p>
              </div>

              <div className="counter__wrapper d-flex align-items-center gap-5 flex-wrap">
                <div>
                  <p className="counter__box">{stats?.successfulTrips}+</p>
                  <h6>Successful trips</h6>
                </div>
                <div >
                  <p className="counter__box">{stats?.regularClients}+</p>
                  <h6>Regular clients</h6>
                </div>
                <div >
                  <p className="counter__box">{stats?.yearsExperience}</p>
                  <h6>Years experience</h6>
                </div>
              </div>
            </Col>
            <Col lg="6" md="12">
              <div className="experience__img">
                <img src={`${experienceImg} `} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Col lg="12">
            <Subtitle subtitle={"Gallery"} />
            <h2 className="gallery_title">Visit our tour gallery</h2>
          </Col>

          <Col lg="12">
            <MasonryImagesGallery />
          </Col>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg="12">
              <Subtitle subtitle={"Fans Love"} />
              <h2 className="testimonial__title">What our fans say about us</h2>
            </Col>

            <Col lg="12">
              <Testimonials />
            </Col>
          </Row>
        </Container>
      </section>

      <Newsletter />
    </>
  );
};

export default Home;
