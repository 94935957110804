import React from 'react'
import StatisticsList from '../Admin_Tour/StatisticsList';
import OffersList from '../Admin_Tour/OffersList';
import Subtitle from '../../../shared/Subtitle';
import Touroperator from '../Admin_Tour/TourOperator';
import CompanyDetails from '../Admin_Tour/CompanyDetails';
import UserDetails from '../Admin_Tour/UserDetails';
import Update_Gallery from '../Admin_Tour/Update_Gallery';
import Get_Tour from '../Admin_Tour/GetTour';
import BookingDetails from '../BookingDetails';
import MediaDetails from '../Admin_Tour/MediaDetails';
import SEO from '../../../SEO';
const Admin_Dashboard = () => {
      return (
    <div className='container'>     
 {/* SEO - Admin Dashboard */}
 <SEO
        title="Admin Dashboard-Tripwaly"
        description="Access the Admin Dashboard on Tripwaly to oversee all platform activities, manage users, monitor tours, handle bookings, and analyze performance metrics for enhanced operations."
        keywords="Admin Dashboard, Tripwaly, user management, tour management, booking management, analytics, performance metrics, platform administration, admin tools, travel platform control"
      url="admin/dashboard"
      />
<div>
      <Subtitle subtitle={"Tours"} />
<Get_Tour />
</div>
<div className='my-2'>
      <Subtitle subtitle={"Booking Details"} />
<BookingDetails />
</div>
     <div>

      <Subtitle subtitle={"Company "} />
<CompanyDetails />
</div>
<div>
      <Subtitle subtitle={"User's "} />
<UserDetails />
</div>
      <div>
      <Subtitle subtitle={"Your journey, your story!"} />   
<Touroperator />
</div>            
      <div>
      <Subtitle subtitle={"What we serve"} />            
<OffersList />
</div>
      <div>
      <Subtitle subtitle={"Experience"} />
<StatisticsList />
</div>

<div>
      <Subtitle subtitle={"Gallery"} />
<Update_Gallery />
</div>

    </div>
  )
}

export default Admin_Dashboard
