import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaMapMarkerAlt,FaPlane ,FaStar,FaStarHalfAlt} from 'react-icons/fa'; // Importing icons from react-icons
import '../styles/companies.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { Companyname } from '../components/Redux Toolkit/authSlice';
import { Port } from '../Port';
import SEO from '../SEO';

const Companies = () => {
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  const fetchCompanies = async () => {
    try {
      const res = await axios.get(`${Port}/company/get`);
      setCompanies(res.data);
    } catch (err) {
      toast.error("Company data not fetched");
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    const texts = [
      "Hello And Welcome",
      "Here's The Information About",
      "Different Companies Tours"
    ];

    const changeText = () => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    };

    const interval = setInterval(() => {
      changeText();
    }, 5000); // Change text every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container-fluid">
<SEO
        title="Companies-Tripwaly"
        description="Find trusted travel companies to book your dream tour with Tripwaly."
        keywords="companies, travel, tours, booking, Tripwaly"
      url="companies/tour"
      />
      
      <ToastContainer />
      <div className="background-image-companies">
        <div className="animated-text">
          
          <div className={`text ${currentTextIndex === 0 ? 'active' : 'hidden'}`}>Hello And Welcome</div>
          <div className={`text ${currentTextIndex === 1 ? 'active' : 'hidden'}`}>Here's The Information About</div>
          <div className={`text ${currentTextIndex === 2 ? 'active' : 'hidden'}`}>Different Companies Tours</div>
        </div>
      </div>

      <div className="cards-section">
  <div className="card-container container">
    {companies.map(company => (
      <Link key={company.id} to={`/companies/${company.companyName.toLowerCase().replace(/\s+/g, '-')}/tours`} className="company-card-link">
        <div className="company-card" onClick={() => dispatch(Companyname(company.companyName))}>
        <div className="card-header">
  <img
    src={`${company.companyimage}`}
    alt={`${company.companyName} logo`}
    className="company-logo"
  />
  <div className="company-info">
  <p className="company-rating">
      {Array.from({ length: 5 }, (_, index) => {
        if (index <Math.floor(company.countcompanyrating/company.counttour)) {
          return <FaStar key={index} className="star filled" />;
        }
        if (index === Math.floor(company.countcompanyrating/company.counttour) && company.countcompanyrating/company.counttour % 1 !== 0) {
          return <FaStarHalfAlt key={index} className="star filled" />;
        }
        return <FaStar key={index} className="star" />;
      })}
      </p>
    <h2 className="company-name">{company.companyName}</h2>
    <p className="company-location">
      <FaMapMarkerAlt className="icon" />
      <strong>{company.companyAddress}</strong>
    </p>
    <p className="company-tours">
      <FaPlane className="icon" /> <strong>: {company.counttour}</strong>
    </p>
    {/* Rating field with 3.5 stars */}
  </div>
</div>

          <div className="card-content">
            <p className="company-description">{company.description}</p>
            <Link to={`/companies/${company.companyName.toLowerCase().replace(/\s+/g, '-')}/tours`} className="view-details-button">
              View Details
            </Link>
          </div>
        </div>
      </Link>
    ))}
  </div>
</div>

    </div>
  );
};

export default Companies;
