import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa'; // Importing the edit icon
import '../Admin/Admincss/CompanySection.css';
import { Port } from '../../Port';

const CompanyDetails = () => {
  const detail = useSelector((state) => state.auth.login.companyName);
  const [getCompany, setCompany] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [newImage, setNewImage] = useState(null);

  const CompanyDetail = async () => {
    try {
      const response = await axios.get(`${Port}/company/get`);
      const companies = response.data;
      const filteredCompanies = companies.filter((val) => val.companyName === detail);
      setCompany(filteredCompanies);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    CompanyDetail();
  }, []);

  const handleEdit = (index) => {
    setEditingIndex(index);
    setNewImage(null);
  };

  const handleCancel = () => {
    setEditingIndex(null);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setCompany((prevCompanies) =>
      prevCompanies.map((company, i) =>
        i === index ? { ...company, [name]: value } : company
      )
    );
  };

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    setNewImage(file);
    setCompany((prevCompanies) =>
      prevCompanies.map((company, i) =>
        i === index ? { ...company, companyimage: URL.createObjectURL(file) } : company
      )
    );
  };

  const handleSave = async (index) => {
    const companyToUpdate = getCompany[index];
    const formData = new FormData();

    Object.keys(companyToUpdate).forEach((key) => {
      formData.append(key, companyToUpdate[key]);
    });

    if (newImage) {
      formData.append('images', newImage);
    }

    try {
      const response = await axios.put(
        `${Port}/company/update/${companyToUpdate._id}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      setCompany((prevCompanies) =>
        prevCompanies.map((company, i) =>
          i === index ? response.data : company
        )
      );
      setEditingIndex(null);
    } catch (error) {
      console.error('Error updating company:', error);
    }
  };

  return (
    <section className="company-section">
      {getCompany &&
        getCompany.map((company, index) => (
          <div key={index} className="company-cards">
            <div className="company-images">
              <img
                src={`${company.companyimage}`}
                alt={company.companyName}
              />
              {editingIndex === index && (
                <div className="image-edit-container">
                  <input
                    id={`imageUpload-${index}`}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(e) => handleImageChange(e, index)}
                  />
                  <button
                    className="image-edit-icon"
                    onClick={() =>
                      document.getElementById(`imageUpload-${index}`).click()
                    }
                  >
                    <FaEdit size={20} />
                  </button>
                </div>
              )}
            </div>
            <div className="company-details my-2">
              {editingIndex === index ? (
                <>
                  <input
                    type="text"
                    className="form-control mb-2"
                    name="companyName"
                    value={company.companyName}
                    onChange={(e) => handleChange(e, index)}
                  />
                  <input
                    type="text"
                    className="form-control mb-2"
                    name="name"
                    value={company.name}
                    onChange={(e) => handleChange(e, index)}
                  />
                  <input
                    type="email"
                    className="form-control mb-2"
                    name="email"
                    value={company.email}
                    onChange={(e) => handleChange(e, index)}
                  />
                  <input
                    type="tel"
                    className="form-control mb-2"
                    name="phoneNumber"
                    value={company.phoneNumber}
                    onChange={(e) => handleChange(e, index)}
                  />
                  <input
                    type="text"
                    className="form-control mb-2"
                    name="companyAddress"
                    value={company.companyAddress}
                    onChange={(e) => handleChange(e, index)}
                  />
                  <textarea
                    className="form-control mb-2"
                    name="description"
                    value={company.description}
                    onChange={(e) => handleChange(e, index)}
                  />
                  <button
                    className="btn btn-sm bookings__btn"
                    onClick={() => handleSave(index)}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-sm bookings__btn ms-2"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <>
                  <h2 className="company-names">{company.companyName}</h2>
                  <p>
                    Owner Name: <strong>{company.name}</strong>
                  </p>
                  <p>
                    Email: <strong>{company.email}</strong>
                  </p>
                  <p>
                    Phone No.: <strong>0{company.phoneNumber}</strong>
                  </p>
                  <p>
                    Company Address: <strong>{company.companyAddress}</strong>
                  </p>
                  <p className="company-descriptions">{company.description}</p>
                  <button
                    className="btn btn-sm bookings__btn"
                    onClick={() => handleEdit(index)}
                  >
                    Update
                  </button>
                </>
              )}
            </div>
          </div>
        ))}
    </section>
  );
};

export default CompanyDetails;
