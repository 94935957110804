// src/components/Create_Tour.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { setTourid } from '../../Redux Toolkit/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faUpload, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import "../Admincss/CreatTour.css"
import { useSelector } from 'react-redux';
import { Port } from '../../../Port';
import SEO from '../../../SEO';
const categorydata=["Cultural", "Adventure", "Religious", "Leisure", "Event", "Historical", "Wildlife", "Beach", "Eco-Tourism", "Mountain/Climbing", "City Tours", "Rural Exploration", "Photography", "Food & Culinary", "Shopping", "Hiking", "Safari", "Luxury", "Festival", "Spiritual", "Wellness & Spa", "Cruise", "Extreme Sports", "Winter Sports", "Desert Safari", "Urban Exploration", "Island Hopping", "Agritourism", "Wine & Vineyard", "Architecture", "Museum & Art", "National Parks", "Family Friendly", "Solo Travel", "Youth Travel", "Educational Tours", "Bird Watching", "Fishing & Angling", "Volunteer Tourism", "Road Trips", "Caving", "Sports Events", "Music & Concerts", "Science & Technology", "Language Immersion", "Space Tourism", "Backpacking", "Heritage Sites", "Meditation & Yoga", "Railway Journeys", "Pet-Friendly Tours", "Golf Tours", "Cycling", "Kayaking", "Sailing", "Rafting", "Glamping", "National Heritage", "Off-Roading", "Ziplining", "Haunted & Paranormal", "Underwater Diving", "Rock Climbing", "Forest Retreats", "Vegan & Organic Food Tours", "Health & Detox Retreats", "Horseback Riding", "Ancient Ruins", "Pilgrimages", "Bungee Jumping", "Botanical Gardens", "Cycling & Bike Tours", "Sunset Cruises", "Luxury Train Journeys", "Stargazing", "Chasing Northern Lights", "Mangrove Exploration", "Agricultural Fair Visits", "Tea & Coffee Plantation Tours", "Marine Wildlife Watching"]
const Create_Tour = () => {
  const role=useSelector((state)=>state.auth.login.role);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [cities, setCities] = useState([]);
  const [images, setImages] = useState([]); 

  // Individual state variables
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [fromcity, setFromCity] = useState('');
  const [tocity, setToCity] = useState('');
  const [distance, setDistance] = useState('');
  const [location, setLocation] = useState('');
  const [price, setPrice] = useState('');
  const [postcode, setPostcode] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [fromlatitude, setfromLatitude] = useState('');
  const [fromlongitude, setfromLongitude] = useState('');
  const [duration, setDuration] = useState('');
  const [fromdate, setFromDate] = useState('');
  const [time, settime] = useState('');
  const [todate, setToDate] = useState('');
  const [availableseats, setAvailableSeats] = useState('');
  const [category, setCategory] = useState([]);
  const [tags, setTags] = useState(['']);
  const [admin,setAdmin] = useState();
  const [company, setCompany] = useState('');
  const [imageName, setImageName] = useState(null);
  const handleImageChanges = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageName(file.name);
    }
  };

  const handleRemoveImage = () => {
    setImageName(null);
    document.getElementById("customFileInput").value = ""; // Reset file input
  };

    const handleCategoryChanges = (event) => {
      const selectedCategory = event.target.value;
  
      // Avoid adding empty or duplicate values
      if (selectedCategory && !category.includes(selectedCategory)) {
        setCategory([...category, selectedCategory]);
      }
    };
  
    const handleRemoveCategorys = (indexToRemove) => {
      setCategory(category.filter((_, index) => index !== indexToRemove));
    };
  
  useEffect(() => {
    if (role === "admin") {
      setAdmin(role);
    } else if (role === "company") {
      setCompany(role);
    }
  }, [role]);
  useEffect(() => {
    axios
      .get(`${Port}/tour/city/get`)
      .then(response => {
        // Extract city names from the response
        const cityData = response.data[0].citytour;
        const cityOptions = cityData.map(city => ({ value: city.cityname, label: city.cityname }));
        setCities(cityOptions); // Update state with formatted city options
console.log(cityOptions)
      })
      .catch(error => {
        console.error('Error fetching cities:', error);
      });
  }, []);
  
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImages([...images, file]);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault(); 
    // Date validation
    const fromdateObj = new Date(fromdate);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to midnight for date-only comparison
  
    if (fromdateObj < today) {
      toast.error("Date should be today or a future date");
      return;
    }
  
   
    const formDataToSend = new FormData();
    // Append individual fields to formData
    formDataToSend.append('title', title);
    formDataToSend.append('description', description);
    formDataToSend.append('fromcity', fromcity);
    formDataToSend.append('tocity', tocity);
    formDataToSend.append('distance', distance);
    formDataToSend.append('location', location);
    formDataToSend.append('time', time);
    formDataToSend.append('price', price);
    formDataToSend.append('postcode', postcode);
    formDataToSend.append('latitude', latitude);
    formDataToSend.append('longitude', longitude);
    formDataToSend.append('fromlatitude', fromlatitude);
    formDataToSend.append('fromlongitude', fromlongitude);
    formDataToSend.append('duration', duration);
    formDataToSend.append('fromdate', fromdate);
    formDataToSend.append('todate', todate);
    formDataToSend.append('availableseats', availableseats);
    formDataToSend.append('admin', admin);
    formDataToSend.append('company', company);
    category.forEach((cat, index) => {
      formDataToSend.append(`category[${index}]`, cat);
    });  
    // Append images to formData
    images.forEach((image) => {
      formDataToSend.append('images', image);
    });
  
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${Port}/tour/create`, formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      const createdTour = response.data;
      dispatch(setTourid(createdTour._id));
      toast.success('Tour created successfully!');
      navigate('/admin/dashboard/create/tour/schedule');
    } catch (error) {
      
        toast.error(`Error creating tour: ${error.message}`);
      }
    }

  
  return (
    <div className="container mt-4">
  {/* SEO */}
  <SEO
        title="Create New Tour-Tripwaly"
        description="Create and publish new tours with Tripwaly. Easily add tour details, images, pricing, and availability to showcase your travel packages and attract potential customers"
        keywords="create tour, add new tour, Tripwaly tour creation, travel package, tour details, tour management, add tour images, tour pricing, tour availability, travel agency, Tripwaly tours, publish tour, new travel package"
        url="create/tour"
      />
   
      <ToastContainer />
      <h4 className="text-center mb-4 heading">Create Tour</h4>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        {/* Tour Fields */}
        <div className="row">
          <div className="col-md-6 mb-2">
            <label>Title</label>
            <input

              type="text"
              className="form-control shadow-sm p-2"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            autoComplete='on'  
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Description</label>
            <textarea
              className="form-control  shadow-sm p-2"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
     
            autoComplete='on'
            />
          </div>
        </div>
        <div className='row'>
          <div className="col-md-6 mb-3">
          <label>Select from city</label>
          <Select
   options={cities}
   value={fromcity ? { value: fromcity, label: fromcity } : null}
   onChange={(option) => setFromCity(option ? option.value : '')}
  
   autoComplete='on'
   placeholder="Select a from city"
  styles={{
    control: (baseStyles, state) => ({
      ...baseStyles,
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Subtle shadow
      padding: "4px", // Inner padding
      border: "1px solid #ced4da", // Bootstrap form control border
      '&:hover': {
        borderColor: '#86b7fe', // Hover effect like Bootstrap
      },
      backgroundColor: state.isDisabled ? "#e9ecef" : "white", // Match disabled state
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: "#6c757d", // Bootstrap placeholder color
    }),
  }}
/>

          </div>
          <div className="col-md-6 mb-3">
          <label>Select to city</label>
          <Select
  options={cities}
  value={tocity ? { value: tocity, label: tocity } : null}
  onChange={(option) => setToCity(option ? option.value : '')}
  
  autoComplete="on"
  placeholder="Select to city"
  styles={{
    control: (baseStyles, state) => ({
      ...baseStyles,
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Subtle shadow
      padding: "4px", // Inner padding
      border: "1px solid #ced4da", // Bootstrap form control border
      '&:hover': {
        borderColor: '#86b7fe', // Hover effect like Bootstrap
      },
      backgroundColor: state.isDisabled ? "#e9ecef" : "white", // Match disabled state
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: "#6c757d", // Bootstrap placeholder color
    }),
  }}
/>

          </div>
        </div>
        {/* Distance Location */}
       
        {/* <div className='row'>
        <div className="col-md-6 mb-3">
        <label>Distance</label>
            <input

              type="text"
              className="form-control"
              value={distance}
              onChange={(e) => setDistance(e.target.value)}
              
            autoComplete='on'
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Location</label>
            <input

              type="text"
              className="form-control"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              
            autoComplete='on'
            />
          </div>
        </div> */}
        
        {/* Price, postcode */}
        <div className="row">
          <div className="col-md-6 mb-3">
            <label>Price (Rs)</label>
            <input

              type="number"
              className="form-control  shadow-sm p-2"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              
            autoComplete='on'
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Duration Days</label>
            <input

              type="number"
              className="form-control shadow-sm p-2"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              
            autoComplete='on'
            />
          </div>
       
          {/* <div className="col-md-6 mb-3">
            <label>Postcode</label>
            <input

              type="number"
              className="form-control"
              value={postcode}
              onChange={(e) => setPostcode(e.target.value)}
              
            autoComplete='on'
            />
          </div> */}
        </div>
       
        {/*latitude , longitude */} 
        {/* <div className="row">
          <div className="col-md-6 mb-3">
            <label>Latitude</label>
            <input

              type="number"
              className="form-control"
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
              
            autoComplete='on'
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Longitude</label>
            <input

              type="number"
              className="form-control"
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
              
            autoComplete='on'
            />
          </div>
        </div> */}
       
        {/*latitude , longitude */}
       
        {/* <div className="row">
          <div className="col-md-6 mb-3">
            <label>From City Latitude</label>
            <input

              type="number"
              className="form-control"
              value={fromlatitude}
              onChange={(e) => setfromLatitude(e.target.value)}
              
            autoComplete='on'
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>From City Longitude</label>
            <input

              type="number"
              className="form-control"
              value={fromlongitude}
              onChange={(e) => setfromLongitude(e.target.value)}
              
            autoComplete='on'
            />
          </div>
        </div> */}
       
        {/* Duration */}
      
       {/* From and to date */}
       <div className="row">
        
          <div className="col-md-6 mb-3">
            <label>From Date</label>
            <input

              type="date"
              className="form-control shadow-sm p-2"
              value={fromdate}
              onChange={(e) => setFromDate(e.target.value)}
              
            autoComplete='on'
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>To Date</label>
            <input

              type="date"
              className="form-control shadow-sm p-2"
              value={todate}
              onChange={(e) => setToDate(e.target.value)}
              
            autoComplete='on'
            />
          </div>
          
       
        </div>
        {/* Time seats*/}
        <div className="row">
         
        <div className="col-md-6 mb-3">
           <label>Time</label>
           <input
           
             type="time"
             className="form-control shadow-sm p-2"
             value={time}
             onChange={(e) => settime(e.target.value)}
             
           autoComplete='on'
           />
         </div>
         <div className="col-md-6 mb-3">
           <label>Available Seats</label>
           <input
           
             type="number"
             className="form-control shadow-sm p-2"
             value={availableseats}
             onChange={(e) => setAvailableSeats(e.target.value)}
             
           autoComplete='on'
           />

         </div>
      </div>
      <div className="mb-3">
  {/* Row for Categories and Tags */}
  <div className="row g-4 mb-4">
    {/* Categories Section */}
    <div className="col-md-6">
      <label>Categories</label>
      <div className="position-relative">
        <select
          className="form-control shadow-sm p-2"
          onChange={handleCategoryChanges}
          defaultValue="" // Default to "Select Category"
        >
          <option value="">Select Category</option>
          {categorydata
            .filter((item) => !category.includes(item))
            .map((item, idx) => (
              <option key={idx} value={item}>
                {item}
              </option>
            ))}
        </select>
        <div className="dropdown-icon-container position-absolute end-0 me-3 top-50 translate-middle-y">
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </div>
      {/* Display Categories as Tags */}
      <div className="d-flex flex-wrap gap-2 mt-3">
        {category.map((cat, index) => (
          <span
            key={index}
            className="badge bg-secondary px-3 py-2 d-flex align-items-center"
          >
            {cat}
            <button
              type="button"
              className="btn-close btn-close-white ms-2"
              onClick={() => handleRemoveCategorys(index)}
            />
          </span>
        ))}
      </div>
    </div>
    {/* Tags Section */}
    <div className="col-md-6">
      <label>Tags</label>
      <input
        type="text"
        className="form-control shadow-sm p-2"
        placeholder="Type and press Enter to add a tag"
        onKeyDown={(e) => {
          if (e.key === "Enter" && e.target.value.trim()) {
            e.preventDefault();
            if (!tags.includes(e.target.value.trim())) {
              setTags([...tags, e.target.value.trim()]);
            }
            e.target.value = "";
          }
        }}
      />
      {/* Display Tags as Chips */}
      <div className="d-flex flex-wrap gap-2 mt-3">
        {tags.map((tag, index) => (
          <span
            key={index}
            className="badge bg-secondary text-white px-3 py-2 d-flex align-items-center"
          >
            #{tag}
            <button
              type="button"
              className="btn-close btn-close-white ms-2"
              onClick={() => {
                const newTags = tags.filter((_, i) => i !== index);
                setTags(newTags);
              }}
            />
          </span>
        ))}
      </div>
    </div>
  </div>
</div>

      <div className="form-group mt-4">
  <label htmlFor="customFileInput" className="custom-file-label">
    <FontAwesomeIcon icon={faUpload} className="upload-icon" />
    <span>Upload Image</span>
  </label>
  <input
    required
    type="file"
    id="customFileInput"
    accept="image/*"
    onChange={handleImageChange}
    className="custom-file-input"
  />
</div>

       

        {/* Submit Button */}
        <div className='items-center'>
        <button type="submit" className="btn btn-sm bookings__btn">Create Tour</button>
        </div>
      </form>
    </div>
  );
};
  export default Create_Tour;
