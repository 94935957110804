import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../Admincss/AdminCard.css";
import { AiOutlinePlus } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Port } from "../../../Port";
const Get_Tour = () => {
  const companyname = useSelector((state) => state.auth.login.companyName);
  const [tourSchedules, setTourSchedules] = useState([]); // Store tour schedules
  const navigate = useNavigate(); // useNavigate hook for navigation
  const fetchTourSchedules = async () => {
    try {
      const response = await axios.get(
        `${Port}/tour/schedule/get`
      );
      const filteredSchedules = response.data.filter(schedule => 
        schedule.tourid.admin && schedule.tourid.admin.role === 'admin'
    );
      setTourSchedules(filteredSchedules); // Set the filtered schedules to state
    } catch (error) {
      console.error("Error fetching tour schedules", error);
    }
  };

  useEffect(() => {
    fetchTourSchedules(); // Fetch data when component mounts
  }, []);

  const handleViewMore = (id) => {
    // Navigate to GetToursDetails page with tour ID
    navigate(`/admin/dashboard/get/tour/details/${id}`);
  };

  const handleCreateTour = () => {
    navigate("/admin/dashboard/create/tour");
  };

  return (
    <div className="tour-container">
      <div className="tour-row">
        {/* Add Tour card as the first card */}
        <div className="add-tour-button" onClick={handleCreateTour}>
  <AiOutlinePlus  className="add-tour-icon" />
  <p className="add-tour-text">Add Tour</p>
</div>


        {/* First row with only 3 tour cards */}
        {tourSchedules.slice(0, 3).map((scheduleGroup) => (
          <div key={scheduleGroup._id} className="tour-card">
            <div className="tour-images mx-2">
              {scheduleGroup.tourid.images &&
              scheduleGroup.tourid.images.length > 0 ? (
                <img
                src={`${scheduleGroup.tourid.images[0]}`} 
                alt={`Tour ${scheduleGroup.tourid.title}`}
                className="tour-card-image"
              />
              ) : (
                <p>No images available for this tour.</p>
              )}
            </div>
            <div className="tour-info">
              <p>
               Title:  <strong>{scheduleGroup.tourid.title}</strong> 
              </p>

              <p>
               From: <strong>{scheduleGroup.tourid.fromcity}</strong> 
              </p>
              <p>
               To:  <strong>{scheduleGroup.tourid.tocity}</strong> 
              </p>

              <p>
               Distance: <strong>{scheduleGroup.tourid.distance}</strong> 
              </p>
              <p>
               Price: <strong>{scheduleGroup.tourid.price}</strong> 
              </p>

              {scheduleGroup.schedules.length > 0 && (
                <p>
                Time:  <strong>{scheduleGroup.schedules[0].time}</strong> 
                </p>
              )}
            </div>
            <div className="view-more-btn">
              <button onClick={() => handleViewMore(scheduleGroup._id)}>
                View More
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Render rows of 4 tours after the first row */}
      {tourSchedules
        .slice(3)
        .reduce((rows, scheduleGroup, index) => {
          const rowIndex = Math.floor(index / 4);
          if (!rows[rowIndex]) rows[rowIndex] = [];
          rows[rowIndex].push(scheduleGroup);
          return rows;
        }, [])
        .map((row, rowIndex) => (
          <div className="tour-row" key={rowIndex}>
            {row.map((scheduleGroup) => (
              <div key={scheduleGroup._id} className="tour-card">
                <div className="tour-image">
                  {scheduleGroup.tourid.images &&
                  scheduleGroup.tourid.images.length > 0 ? (
                    <img
                      src={`${scheduleGroup.tourid.images[0]}`}
                       alt={`Tour ${scheduleGroup.tourid.title}`}
                      className="tour-card-image"
                    />
                  ) : (
                    <p>No images available for this tour.</p>
                  )}
                </div>
                <div className="tour-info">
              <p>
               Title:  <strong>{scheduleGroup.tourid.title}</strong> 
              </p>

              <p>
               From: <strong>{scheduleGroup.tourid.fromcity}</strong> 
              </p>
              <p>
               To:  <strong>{scheduleGroup.tourid.tocity}</strong> 
              </p>

              <p>
               Distance: <strong>{scheduleGroup.tourid.distance}</strong> 
              </p>
              <p>
               Price: <strong>{scheduleGroup.tourid.price}</strong> 
              </p>

              {scheduleGroup.schedules.length > 0 && (
                <p>
                Time:  <strong>{scheduleGroup.schedules[0].time}</strong> 
                </p>
              )}
            </div>
                <div className="view-more-btn">
                  <button
                    onClick={() => handleViewMore(scheduleGroup.tourid._id)}
                  >
                    View More
                  </button>
                </div>
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export default Get_Tour;
