import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Admin/Admincss/bookingdetails.css'
import { useSelector } from 'react-redux';
import { Port } from '../../Port';

const BookingDetails = () => {
  const [bookingData, setBookingData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentBooking, setCurrentBooking] = useState(null);
   var logindata=useSelector((state) => state.auth.login);

  // Fetch data from the backend
  const fetchBookingData = async () => {
    try {
        const response = await axios.get(`${Port}/user/booking/get`);
        let fetchedBooking = response.data;
        // Safely access nested properties to avoid undefined errors
        let filteredBooking = fetchedBooking.filter((tour) => {
            const hasCompanyMatch =
                tour.tour?.tourid?.company?.companyName === logindata.companyName;
            const hasAdminMatch =
                tour.tour?.tourid?.admin?.name === logindata.name;
            return hasCompanyMatch || hasAdminMatch;
        });

        setBookingData(filteredBooking);
        console.log('Booking data filtered and set successfully!');
    } catch (error) {
        toast.error('Failed to fetch booking data.');
        console.error('Error fetching booking data:', error);
    }
};
  
  // Handle modal open for editing a booking
  const handleShow = (booking) => {
    setCurrentBooking(booking);
    setShowModal(true);
  };

  // Handle modal close
  const handleClose = () => {
    setShowModal(false);
    setCurrentBooking(null);
  };

  // Update booking details
  const handleUpdate = async (e) => {
    e.preventDefault();
    const { approved, } = e.target.elements;

    try {
      const response = await axios.put(`${Port}/user/booking/update/${currentBooking._id}`, {
         approved: approved.value === 'true',
           });

      setBookingData((prev) => prev.map((b) => (b._id === currentBooking._id ? response.data : b))); // Update local state
      toast.success('Booking updated successfully!'); // Success toast
      handleClose(); // Close modal
    } catch (error) {
      toast.error('Failed to update booking.'); // Error toast
    }
  };

 

  // Delete booking
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${Port}/user/booking/delete/${id}`);
      setBookingData((prev) => prev.filter((booking) => booking._id !== id)); // Update local state
      toast.success('Booking deleted successfully!'); // Success toast
    } catch (error) {
      toast.error('Failed to delete booking.'); // Error toast
    }
  };
  useEffect(() => {
    fetchBookingData();
  }, []);
  return (
    <div className="container">
      <ToastContainer /> {/* Toast container for notifications */}
      {bookingData.length === 0 ? (
  <p>No booking data available.</p>
) : (
  <div className="booking-cards-container">
    {bookingData.map((booking) => (
      <div key={booking._id} className="booking-card p-3">
        <div className="booking-card-header">
          <h5 style={{fontWeight:'bolder' , fontSize:'1.5rem'}}>{booking.fullname}</h5>
          <p style={{fontWeight:'bold' , fontSize:'1rem'}}>{booking.user.email}</p>
        </div>
        <div className="booking-card-body">
          <div className="booking-infos px-1">
            <p className='pt-1'>Phone Number: <strong>{booking.number}</strong> </p>
            <p className='pt-2'>Total Charges: PKR <strong>{booking.totalCharges}</strong></p>
          </div>
          <div className="booking-info px-1">
            <p>Total Persons: <strong>{booking.numOfPersons}</strong></p>
          </div>
          <div className="booking-info px-1 d-flex justify-content-between">
          <p>Kids: <strong>{booking.kid}</strong></p>
      
          </div>
          <div className="booking-info px-1">
            <p>Approved: <strong>{booking.approved ? 'Yes' : 'No'}</strong></p>
          </div>
        </div>
        <div className="booking-card-footer">
          <button className="edit-button " onClick={() => handleShow(booking)}>Edit</button>
          <button className="delete-button" onClick={() => handleDelete(booking._id)}>Delete</button>
        </div>
      </div>
    ))}
  </div>
)}

      {/* Modal for Editing Booking */}
      <Modal show={showModal} onHide={handleClose} dialogClassName="custom-modal" centered>
  <Modal.Header>
    <Modal.Title>Edit Booking</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {currentBooking && (
      <Form onSubmit={handleUpdate}>
        <Form.Group controlId="approved">
          <Form.Label>Approved</Form.Label>
          <Form.Control as="select" defaultValue={currentBooking.approved.toString()} required>
            <option value="true">True</option>
            <option value="false">False</option>
          </Form.Control>
        </Form.Group>
        <Button variant="primary" className='mt-4 flex-left' type="submit">Update Booking</Button>
        <Button variant="primary" className='mt-4 flex-left' onClick={handleClose}>Close</Button>
     
      </Form>
    )}
  </Modal.Body>
</Modal>

     </div>
  );
};

export default BookingDetails;
