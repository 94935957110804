import React from "react";
import "./SearchBar.css";
import { Col, Form, FormGroup } from "reactstrap";
import { useDispatch } from "react-redux";
import { setTourseats, setTourlocation } from "../components/Redux Toolkit/authSlice";

const SearchBar = ({ fetchToursAndSchedules }) => {
  const dispatch = useDispatch();

  const handleLocationChange = (e) => {
    const location = e.target.value;
    dispatch(setTourlocation(location));
    fetchToursAndSchedules(); 
  };

  const handleMaxGroupChange = (e) => {
    const maxGroup = e.target.value;
    dispatch(setTourseats(maxGroup));
    fetchToursAndSchedules(); // Trigger the fetch after setting search criteria
  };

  return (
    <Col lg="12">
    <div className="filter-section">
      <Form className="d-flex align-items-center gap-4">
        <FormGroup className="d-flex gap-3 form__group">
          <div>
            <input
              type="search"
              placeholder="search tour"
              onChange={handleLocationChange}
              className="city-search-input w-100"
            />
          </div>
        </FormGroup>
      </Form>
    </div>
  </Col>
  

  );
};

export default SearchBar;
