import React, { useState } from "react";
import { FaSearch, FaLocationArrow, FaMapMarkerAlt, FaCalendarAlt } from "react-icons/fa"; // Importing Font Awesome icons
import "./SearchComponent.css"; // Import the CSS file for custom styling

const SearchComponent = () => {
  const [startingLocation, setStartingLocation] = useState("");
  const [destination, setDestination] = useState("");
  const [date, setDate] = useState("");

  const handleSearch = () => {
    // Add your search logic here
    console.log(`Searching for tours from ${startingLocation} to ${destination} on ${date}`);
  };

  return (
    <div className="search-container">
      <div className="search-row">
        <div className="input-group">
          <FaLocationArrow className="input-icon" />
          <input
            type="text"
            placeholder="Starting Location"
            className="search-input"
            value={startingLocation}
            onChange={(e) => setStartingLocation(e.target.value)}
          />
        </div>
        
        <div className="input-group">
          <FaMapMarkerAlt className="input-icon" />
          <input
            type="text"
            placeholder="Destination"
            className="search-input"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
          />
        </div>
        
        <div className="input-group">
          <FaCalendarAlt className="input-icon" />
          <input
            type="date"
            className="search-input"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>

        <div className="search-icon" onClick={handleSearch}>
          <FaSearch className="search-btn" />
        </div>
      </div>
    </div>
  );
};

export default SearchComponent;
