import React from 'react'
import Subtitle from '../../shared/Subtitle';
import { useNavigate } from 'react-router-dom';
import BookingDetails from '../Admin/BookingDetails';
import GetCompany_Tour from './GetCompany_Tour';
import CompanySection from './CompanyDetails';
import SEO from '../../SEO';
const Company_Dashboard = () => {
const navigate=useNavigate();
      return (
    <div className='container'>
        {/* SEO */}
        <SEO
        title="Company Dashboard-Tripwaly"
        description="Manage your profile, view tours, track bookings, and customize tour details on your Tripwaly company dashboard. Access essential tools for growing your tour business"
        keywords="company dashboard, Tripwaly, tour management, view bookings, manage profile, tour updates, company profile, Tripwaly tours, travel business management, tour company tools"
      url="company/dashboard"
      />
  
      <div>
      <Subtitle subtitle={"Company"} />
      <CompanySection/>
            </div>     
<div className='my-3'>
      <Subtitle subtitle={"Tours"} />
<GetCompany_Tour />
</div>
<div>
<Subtitle subtitle={"Booking Details"} />
<BookingDetails />
</div>
    </div>
  )
}

export default Company_Dashboard
