import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import './testimonial.css'; // Ensure this CSS file is correctly linked
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import the icons
import { Port } from '../../Port'; // Adjust this import based on your project structure

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(`${Port}/user/review/get`);
        setTestimonials(response.data);
      } catch (error) {
        console.error("Error fetching testimonials", error);
      }
    };

    fetchTestimonials();
  }, []);

  const settings = {
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    prevArrow: (
      <div style={{ width: '60px', height: '60px' }}>
        <FaChevronLeft className="slick-arrow slick-prev" />
      </div>
    ),  // Custom previous arrow
    nextArrow: (
      <div style={{ width: '60px', height: '60px' }}>
        <FaChevronRight className="slick-arrow slick-next" />
      </div>
    ),  // Custom next arrow
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonial-section">
      <Slider {...settings}>
        {testimonials.map((testimonial) => (
          <div className="testimonial" key={testimonial._id}>
            {/* Centered user image */}
            <div className="testimonial-image-container">
              <img
                src={testimonial.images}
                className="testimonial-image"
                alt={testimonial.user?.name || 'User'}
              />
            </div>

            {/* Centered review text */}
            <p className="testimonial-review">{testimonial.review}</p>

            {/* Centered user name */}
            <h6 className="testimonial-user-name">{testimonial.user?.name || 'User'}</h6>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
