import React from 'react';
import { Helmet } from 'react-helmet-async';


const SEO = ({ title, description, keywords,url }) => {
  return (
    <Helmet>
      <title>{title || 'Tripwaly'}</title>
      <meta name="description" content={description || 'Default description for Tripwaly.'} />
      <meta name="keywords" content={keywords || 'Default, keywords, Tripwaly'} />
      <meta name="author" content="Tripwaly" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta http-equiv="X-UA-Compatible" content="ie=edge" />
      <meta property="og:title" content={title || 'Tripwaly'} />
      <meta property="og:description" content={description || 'Default description for Tripwaly.'} />
      <meta property="og:url" content={`https://tripwaly.com/${url}`} />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};

export default SEO;
