import React, { useState, useRef, useEffect } from 'react';
import { Form } from 'reactstrap';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../styles/tour-details.css";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { Port } from '../Port';

const Review = ({ tourscheduleid }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.login);
  const [tourRating, setTourRating] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showData, setShowData] = useState(false);
  const reviewMsgRef = useRef();

  const handleRatingClick = (rating) => {
    setTourRating(rating);
  };

  useEffect(() => {
    const fetchBookingData = async () => {
      try {
        const response = await axios.get(`${Port}/user/booking/get`);
        const checkScheduleId = response.data?.find(
          (tours) =>
            tours.tour &&
            tours.tour._id === tourscheduleid &&
            tours.user._id === user._id
        );
        if (checkScheduleId) {
          setShowData(true);
        }
      } catch (error) {
        console.error("Error fetching booking data:", error);
      }
    };

    fetchBookingData();
  }, [tourscheduleid, user._id]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const reviewMessage = reviewMsgRef.current.value;
    if (!tourRating || !reviewMessage) {
      toast.error('Please provide both a rating and a review');
      return;
    }

    const formData = new FormData();
    formData.append('user', user._id);
    formData.append('tour', tourscheduleid);
    formData.append('review', reviewMessage);
    formData.append('rating', tourRating);
    if (selectedFile) {
      formData.append('images', selectedFile);
    }

    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        toast.error("You need to login first to submit a review.");
        navigate("/logins");
        return;
      }
      const response = await axios.post(`${Port}/user/review/create`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 201) {
        toast.success(response.data.message || 'Review submitted successfully!');
        setTourRating(0);
        reviewMsgRef.current.value = '';
        setSelectedFile(null);
      } else {
        toast.error(response.data.message || 'Failed to submit review');
      }
    } catch (error) {
      toast.error("Please log in to continue.");
    }
  };

  const fileChangeHandler = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <div>
      {showData && (
        <>
          <ToastContainer />
          <div className="tour__reviews mt-4">
            <h4>Reviews</h4>
            <Form onSubmit={submitHandler}>
              <div className="d-flex align-items-center gap-3 mb-4 rating__group">
                {[1, 2, 3, 4, 5].map((value) => (
                  <FaStar
                    key={value}
                    size={30}
                    color={value <= tourRating ? "#ffae42" : "#e4e5e9"}
                    onClick={() => handleRatingClick(value)}
                    style={{ cursor: 'pointer', transition: 'transform 0.2s' }}
                    onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.2)')}
                    onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                  />
                ))}
                <span style={{ fontSize: '14px', marginLeft: '10px', color: 'black' }}>
                  ({tourRating} star{tourRating > 1 ? 's' : ''})
                </span>
              </div>

              <div className="review__input">
                <input type="text" ref={reviewMsgRef} placeholder="Share your thoughts" required />
              </div>

              <div className="button-container mt-3">
                <div className="left-button">
                  <input
                    type="file"
                    id="customFileInput"
                    onChange={fileChangeHandler}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="customFileInput" className="custom-files-label">
                    <FontAwesomeIcon icon={faUpload} className="upload-icon" />
                    Upload
                  </label>
                </div>

                <button className="btn primary__btn text-white" type="submit">
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </>
      )}
    </div>
  );
};

export default Review;
