import React, { useState } from "react";
import { Container, Row, Col, Form, FormGroup, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import "../styles/login.css";
import userIcon from "../assets/images/user.png";
import registerImg from "../assets/images/login.png";
import { useNavigate } from "react-router-dom";
import { Port } from "../Port";
import SEO from "../SEO";

const User_Register = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    username: "",
    email: "",
    password: "",
    phone: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [previewImage, setPreviewImage] = useState(userIcon);
  
  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };


  const handleClick = async (e) => {
    e.preventDefault();

    if (!credentials.phone || credentials.phone.length < 11) {
      toast.error("Phone number must contain at least 11 digits");
      return;
    }

    if (!credentials.password || credentials.password.length <= 6) {
      toast.error("Password must contain more than 6 characters");
      return;
    }

    const formData = new FormData();
    formData.append("name", credentials.username);
    formData.append("email", credentials.email);
    formData.append("password", credentials.password);
    formData.append("phone", credentials.phone);

    if (selectedFile) {
      formData.append("images", selectedFile); // Attach the image file
    }
  
    try {
      const res = await axios.post(`${Port}/user/create`, formData);
      toast.success(res.data);
      navigate("/user/login");
    } catch (error) {
      toast.error(error.response?.data || "Error creating account. Please try again.");
    }
  };
  const [selectedFile, setSelectedFile] = useState(null);

  const handleImageClick = () => {
    document.getElementById("imagesInput").click();
  };
  const fileChangeHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result); 
      };
      reader.readAsDataURL(file); 
      setSelectedFile(file); 
    }
  };

  return (
    <section className="reduce">
  {/* SEO */}

  <SEO
        title="User Signup-Tripwaly"
        description="Join Tripwaly to start exploring the best tours and travel packages. Create your account today to access exclusive deals and manage your bookings effortlessly"
        keywords="Tripwaly user signup, create account, travel signup, tour booking signup, Tripwaly register, new user registration, travel deals, explore tours, account creation"
      url="user/register"
      />
   

      <ToastContainer />
      <Container>
        <Row>
          <Col lg="8" className="m-auto">
            <div className="card-wrapper">
              <div className="login__container d-flex justify-content-between">
                <div className="login__img">
                  <img src={registerImg} alt="" />
                </div>

                <div className="login__form">
                  <Form onSubmit={handleClick}>
                  <FormGroup>
                    <div className="text-center">
                      <img
                        src={previewImage}
                        height="100px"
                        width="100px"
                       alt="Profile Preview"
                        onClick={handleImageClick}
                        required
                       
                     style={{ cursor: "pointer", borderRadius: "50%", objectFit: "cover",cursor: "pointer" }}
  
                    />
                      <input
                        type="file"
                        id="imagesInput"
                        accept="image/*"
                        style={{ display: "none" }} // Hide the actual file input      
                   onChange={fileChangeHandler}
                  
            
          />
                    </div>
                  </FormGroup>

                    <FormGroup>
                      <input
                        type="text"
                        placeholder="Username"
                        id="username"
                        required
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <input
                        type="email"
                        placeholder="Email"
                        id="email"
                        required
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <div className="password__input">
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          id="password"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <input
                        type="number"
                        placeholder="Phone Number"
                        id="phone"
                        required
                        onChange={handleChange}
                      />
                    </FormGroup>

                    <Button className="btn secondary__btn auth__btn" type="submit">
                      Create Account
                    </Button>
                  </Form>

                  <p>
                    Already have an account? <Link to="/user/login">Login</Link>
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default User_Register;
