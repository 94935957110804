import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import "./TourCard.css";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { Tourdetailid } from "../components/Redux Toolkit/authSlice";
import { Port } from "../Port";
const TourCard = () => {
  const dispatch=useDispatch();
  const [tourData, setTourData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Use values from Redux store for search criteria
  const tourLocation = useSelector((state) => state.auth.tourlocation);
  
  // Fetch data whenever tourLocation or tourSeats changes
  useEffect(() => {
    fetchToursAndSchedules();
  }, [tourLocation]); // Fetch when search criteria changes

  const fetchToursAndSchedules = async () => {
    try {
      const tourResponse = await axios.get(`${Port}/tour/schedule/get`);
      const fetchedTours = tourResponse.data; // Fetched tours and schedules

      // Filter based on conditions
      let filteredTours = fetchedTours;

      if (tourLocation ) {
        filteredTours = fetchedTours.filter(tour => {
          const locationMatch = tourLocation 
    ? (tour.tourid.location.includes(tourLocation) || tour.tourid.title.includes(tourLocation) || tour.tourid.distance.includes(tourLocation) 
    || tour.tourid.distance.includes(tourLocation) || tour.tourid.duration.includes(tourLocation)
    || tour.tourid.tocity.includes(tourLocation) 
  ) 
    : true;

          return locationMatch;
        });
      }

      setTourData(filteredTours); // Set the filtered tours data
    } catch (err) {
      console.error('Error fetching tours and schedules:', err);
      toast.error('Error fetching tours and schedules');
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;


  return (
    <div className="tour__card">
      <ToastContainer />
      <div className="row">
        {tourData.map((tour, index) => (
          tour.tourid.availableseats === 0 ? null : (
            <div key={index} className="col-lg-3 col-md-6 col-sm-12 mb-4">
              <Card className="d-flex">
                {tour.tourid.images && tour.tourid.images.length > 0 && (
                  <div>
                  <span className="tour__img">
                      <img
                          src={`${tour.tourid.images[0]}`} // Assuming tour.tourid.images[0] contains the image URL
                           alt={`Image ${tour.tourid.images[0]}`}
                          className="tour__image" // Adding a class for styling
                      />
                  </span>
              </div>
                )}
                <CardBody>
                  <div className="card__top d-flex align-items-center justify-content-between">
                    <span className="tour__location d-flex align-items-center gap-1">
                      <i className="ri-map-pin-line"></i>
                      {/* Displaying the start and end locations */}
                {`${tour.tourid.fromcity} to ${tour.tourid.location}`}
                    </span>
                    <span className="tour__rating d-flex align-items-center gap-1">
                      <i className="ri-star-fill"></i>
                      {tour.tourid.avgRating === 0 ? null : tour.tourid.avgRating}
                      {tour.tourid.totalRating === 0 ? "Not rated" : <span>{tour.rating} reviews</span>}
                    </span>
                  </div>

                  <h5 className="tour__title">
                    {tour.tourid.title.length > 31 ? (
                      `${tour.tourid.title.slice(0, 28)}...`
                    ) : (
                      <Link to={`/tours/${tour._id}`}>{tour.tourid.title}</Link>
                    )}
                  </h5>

                  <div className="card__bottom d-flex align-items-center justify-content-between mt-3">
                    <h5>
                      PKR {tour.tourid.price}
                      <span>/person</span>
                    </h5>

                    <button className="btn btn-sm booking__btn">
                      <Link to={`/tours/${tour.tourid.slug}`} onClick={()=>dispatch(Tourdetailid(tour._id))}>Book Now</Link>
                    </button>
                  </div>
                </CardBody>
              </Card>
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default TourCard;
