import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import "../shared/TourCard.css";
import "../styles/CompanyTours.css";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Tourdetailid } from "../components/Redux Toolkit/authSlice";
import SearchBar from "../shared/SearchBar";
import { Port } from "../Port";
import SEO from "../SEO";
const CityTours = () => {
  const companyname = useSelector((state) => state.auth.companyname);

  const dispatch = useDispatch();
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [tourData, setTourData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const texts = [
      "These Are Our Servicies Available For You.",
      "So Check Out Our Latest Tours.",
      "And Enjoy a Good Journey.",
    ];

    const changeText = () => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    };

    const interval = setInterval(() => {
      changeText();
    }, 5000); // Change text every 5 seconds

    return () => clearInterval(interval);
  }, []);

  // Get the search criteria from the Redux store
  const tourLocation = useSelector((state) => state.auth.tourlocation);

  const fetchToursAndSchedules = async () => {
    try {
      const tourResponse = await axios.get(`${Port}/tour/get`);
      const fetchedTours = tourResponse.data;
  
      let filteredTours = fetchedTours.filter(
        (tour) => tour.company && tour.company.companyName === companyname
      );
  
      // Further filter tours based on search criteria (tourLocation)
      if (tourLocation) {
        filteredTours = filteredTours.filter((tour) => {
          return (
            tour.location?.toLowerCase().includes(tourLocation) ||
            tour.title?.toLowerCase().includes(tourLocation) ||
            tour.distance?.toLowerCase().includes(tourLocation) ||
            tour.fromcity?.toLowerCase().includes(tourLocation) ||
            tour.duration?.toLowerCase().includes(tourLocation)
          );
        });
      }
  
      setTourData(filteredTours);
    } catch (err) {
      console.error("Error fetching tours and schedules:", err);
      toast.error("Error fetching tours and schedules");
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchToursAndSchedules();
  }, [companyname, tourLocation]); // Include dependencies
   
  if (loading) 
    return <div className="d-flex justify-content-center align-items-center vh-100 fw-bolder text-warning">
  Loading...
</div>
  if (error) return <div>{error}</div>;

  return (
    <div className="container-fluid">
{/* SEO */   }
<SEO
        title={`${companyname} Tours-Explore Amazing Destinations`}
        description={`Discover the best tours and travel packages with ${companyname}. Explore top destinations, city tours, adventure trips, and vacation experiences. Book your journey to exciting cities like Islamabad, Lahore, Karachi, and beyond.`}
        keywords="Admin Dashboard, Tripwaly, user management, tour management, booking management, analytics, performance metrics, platform administration, admin tools, travel platform control"
      url="company/tours"
      />
      {/* Background Image Section */}
      <div className="background-image-company-tour">
        <div className="animated-text">
        <div className={`text ${currentTextIndex === 0 ? 'active' : 'hidden'}`}>{companyname} - Your Trusted Travel Partner</div>
        
          <div
            className={`text ${currentTextIndex === 1 ? "active" : "hidden"}`}
          >
            These Are Our Services For You.
          </div>
          <div
            className={`text ${currentTextIndex === 2 ? "active" : "hidden"}`}
          >
            Check Out Our Latest Tours.
          </div>
          <div
            className={`text ${currentTextIndex === 3 ? "active" : "hidden"}`}
          >
            And Enjoy a Good Journey.
          </div>
        </div>
      </div>
        <ToastContainer />
        <SearchBar />
      <ToastContainer />
      <div className="tour__card container">
  <div className="row">
    {tourData.map((tour, index) =>
      tour.availableseats === 0 ? null : (
        <div key={index} className="col-lg-3 col-md-6 col-sm-12 mb-4">
          <Card className="d-flex h-100">
            {/* Wrap the card content in a Link */}
            <Link
              to={`/tours/${tour.slug}`}
              onClick={() => dispatch(Tourdetailid(tour._id))}
              className="card-link-wrapper"
            >
              {/* Image Section */}
              {tour.images && tour.images.length > 0 && (
                <div>
                  <span className="tour__img">
                    <img
                      src={`${tour.images[0]}`}
                       alt={`Image ${tour.images[0]}`}
                      className="tour__image"
                    />
                  </span>
                </div>
              )}
              <CardBody className="d-flex flex-column justify-content-between">
                <div className="card__top d-flex align-items-center justify-content-between">
                  <span className="tour__location d-flex align-items-center gap-1">
                    <i className="ri-map-pin-line"></i>
                    <span className="truncate-text text-semibold">{`${tour.fromcity} to ${tour.tocity}`}</span>
                  </span>
                  <span className="tour__rating d-flex align-items-center gap-1">
  {tour.avgRating === 0 ? (
    <span>Not Rated</span>
  ) : (
    <div className="star-rating">
      {[...Array(5)].map((_, index) => (
        <i
          key={index}
          className={`ri-star-fill ${index < tour.avgRating ? "filled" : ""}`}
        ></i>
      ))}
    </div>
  )}
</span>
                </div>
                <h5 className="tour__title">
                  {tour.title.length > 31 ? (
                    `${tour.title.slice(0, 28)}...`
                  ) : (
                    tour.title
                  )}
                </h5>

                {/* Date and Time Section */}
                <div className="tour__date-time d-flex justify-content-between align-items-center gap-3 mt-2">
                  <span className="tour__date d-flex align-items-center gap-1">
                    <i className="ri-calendar-line"></i>
                    <span className="text-semibold">
                      {new Date(tour.fromdate).toLocaleDateString()}
                    </span>
                  </span>
                  <span className="tour__time d-flex align-items-center gap-1">
                    <i className="ri-time-line"></i>
                    <span className="text-semibold">
                      {tour.time
                        ? new Date(
                            `${new Date().toISOString().split("T")[0]}T${
                              tour.time
                            }`
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        : "Time not available"}
                    </span>
                  </span>
                </div>

                <div className="card__bottom d-flex justify-content-between align-items-center justify-content-between mt-3">
  <h5>
    {tour.price}<span className="small-currency">₨</span><span>/person</span>
  </h5>
  <button className="btn btn-sm bookings__btn">
    <Link
      to={`/tours/${tour.slug}`}
      onClick={() => dispatch(Tourdetailid(tour._id))}
    >
      Book Now
    </Link>
  </button>
</div>

              </CardBody>
            </Link>

          </Card>
        </div>
      )
    )}
  </div>
</div>

      </div>
  );
};

export default CityTours;
