import axios from "axios";
import React, { useState, useEffect } from "react";
import "../Admincss/UpdatTour.module.css"; // Import the CSS file for styling
import { Port } from "../../../Port";

const UpdateTour = ({ tourdata, close }) => {
  const [formData, setFormData] = useState({});
  const [categories, setCategories] = useState(
    tourdata.map((tour) => tour.tourid.category || [])
  );
  const [cities, setCities] = useState([]);
  const categoryData = [
    "Cultural",
    "Adventure",
    "Religious",
    "Leisure",
    "Event",
    "Historical",
    "Wildlife",
    "Beach",
    "Eco-Tourism",
    "Mountain/Climbing",
    "City Tours",
    "Rural Exploration",
    "Photography",
    "Food & Culinary",
    "Shopping",
    "Hiking",
    "Safari",
    "Luxury",
    "Festival",
    "Spiritual",
    "Wellness & Spa",
    "Cruise",
    "Extreme Sports",
    "Winter Sports",
    "Desert Safari",
    "Urban Exploration",
    "Island Hopping",
    "Agritourism",
    "Wine & Vineyard",
    "Architecture",
    "Museum & Art",
    "National Parks",
    "Family Friendly",
    "Solo Travel",
    "Youth Travel",
    "Educational Tours",
    "Bird Watching",
    "Fishing & Angling",
    "Volunteer Tourism",
    "Road Trips",
    "Caving",
    "Sports Events",
    "Music & Concerts",
    "Science & Technology",
    "Language Immersion",
    "Space Tourism",
    "Backpacking",
    "Heritage Sites",
    "Meditation & Yoga",
    "Railway Journeys",
    "Pet-Friendly Tours",
    "Golf Tours",
    "Cycling",
    "Kayaking",
    "Sailing",
    "Rafting",
    "Glamping",
    "National Heritage",
    "Off-Roading",
    "Ziplining",
    "Haunted & Paranormal",
    "Underwater Diving",
    "Rock Climbing",
    "Forest Retreats",
    "Vegan & Organic Food Tours",
    "Health & Detox Retreats",
    "Horseback Riding",
    "Ancient Ruins",
    "Pilgrimages",
    "Bungee Jumping",
    "Botanical Gardens",
    "Cycling & Bike Tours",
    "Sunset Cruises",
    "Luxury Train Journeys",
    "Stargazing",
    "Chasing Northern Lights",
    "Mangrove Exploration",
    "Agricultural Fair Visits",
    "Tea & Coffee Plantation Tours",
    "Marine Wildlife Watching",
  ];

  useEffect(() => {
    axios
      .get(`${Port}/tour/city/get`)
      .then((response) => {
        const cityData = response.data[0].city;
        setCities(cityData.map((city) => ({ value: city, label: city })));
      })
      .catch((error) => {
        console.error("Error fetching cities:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCategoryChange = (tourIndex, catIndex, value) => {
    const updatedCategories = [...categories];
    updatedCategories[tourIndex][catIndex].title = value;
    setCategories(updatedCategories);
  };

  const addCategory = (tourIndex) => {
    const updatedCategories = [...categories];
    updatedCategories[tourIndex].push({ title: "" });
    setCategories(updatedCategories);
  };

  const removeCategory = (tourIndex, catIndex) => {
    const updatedCategories = [...categories];
    updatedCategories[tourIndex].splice(catIndex, 1);
    setCategories(updatedCategories);
  };

  const handleSubmit = async (e, id, tourIndex) => {
    e.preventDefault();
    const updatedTags = [
      formData.title || tourdata[tourIndex].tourid.title,
      formData.fromcity || tourdata[tourIndex].tourid.fromcity,
      formData.tocity || tourdata[tourIndex].tourid.tocity,
      formData.description || tourdata[tourIndex].tourid.description,
    ];

    const form = new FormData();
    for (let key in formData) {
      form.append(key, formData[key]);
    }

    categories[tourIndex].forEach((category, index) => {
      form.append(`category[${index}][title]`, category.title);
    });

    updatedTags.forEach((tag) => {
      form.append("tags", tag);
    });

    try {
      const response = await axios.put(
        `${Port}/tour/update/${id}`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Data updated successfully", response.data);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  return (
    <div className="">
    {tourdata.map((tour, tourIndex) => (
      <form
        key={tourIndex}
        onSubmit={(e) => handleSubmit(e, tour.tourid._id, tourIndex)}
        className=""
      >
        {/* Tour Images */}
        <div className="">
          {tour.tourid.images && tour.tourid.images.length > 0 ? (
            tour.tourid.images.map((image, index) => (
              <img
                key={index}
                src={`${image}`}
                alt={`Tour ${tour.tourid.title} Image ${index}`}
                className="img-fluid mb-2"
                style={{ maxHeight: '200px', objectFit: 'cover' }}
              />
            ))
          ) : (
            <p>No images available for this tour.</p>
          )}
        </div>
  
        {/* Form Fields */}
        <div className="row ">
          <div className="col-md-6 ">
            <label className="form-label">Title:</label>
            <input
              type="text"
              name="title"
              onChange={handleChange}
              defaultValue={tour.tourid.title}
              className="form-control"
            />
          </div>
          <div className="col-md-6 ">
            <label className="form-label">Description:</label>
            <input
              type="text"
              name="description"
              onChange={handleChange}
              defaultValue={tour.tourid.description}
              className="form-control"
            />
          </div>
        </div>
  
        <div className="row ">
          <div className="col-md-6 ">
            <label className="form-label">From City:</label>
            <select
              name="fromcity"
              onChange={handleChange}
              defaultValue={tour.tourid.fromcity}
              className="form-select"
            >
              {cities.map((city) => (
                <option key={city.value} value={city.value}>
                  {city.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6 mb-2">
            <label className="form-label">To City:</label>
            <select
              name="tocity"
              onChange={handleChange}
              defaultValue={tour.tourid.tocity}
              className="form-select"
            >
              {cities.map((city) => (
                <option key={city.value} value={city.value}>
                  {city.label}
                </option>
              ))}
            </select>
          </div>
        </div>
  
        {/* Categories Section */}
        <h4 className="">Categories</h4>
        <div
          style={{ overflowY: 'auto', maxHeight: '150px' }}
          className="border p-2 "
        >
        {categories[tourIndex].map((cat, catIndex) => (
            <div key={catIndex} className="d-flex align-items-center mb-2">
              <select
                value={cat.title}
                className="form-select form-select-sm me-2"
                onChange={(e) =>
                  handleCategoryChange(tourIndex, catIndex, e.target.value)
                }
              >
                {categoryData.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
              <button
                type="button"
                onClick={() => removeCategory(tourIndex, catIndex)}
                className="btn btn-danger btn-sm"
              >
                Remove
              </button>
            </div>
          ))}
        </div>
        <button
          type="button"
          onClick={() => addCategory(tourIndex)}
          className="btn btn-primary btn-sm "
        >
        Add Category
        </button>
  
        {/* Form Buttons */}
        <div className="d-flex justify-content-between">
          <button type="submit" className="btn btn-success" onClick={close}>
            Update Tour
          </button>
          <button type="button" className="btn btn-secondary" onClick={close}>
            Close
          </button>
        </div>
      </form>
    ))}
  </div>
  
  );
};

export default UpdateTour;
