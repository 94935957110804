import React from "react";
import { Routes, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Home from "../pages/Home";
import Login from "../pages/UserLogin";
import UserRegister from "../pages/UserRegister";
import Tours from "../pages/Tours";
import TourDetails from "../pages/TourDetails";
import Thankyou from "../pages/Thankyou";
import Dashboard from "../components/Admin/Admin Dashboard/Dashboard";
import CreateTour from "../components/Admin/Admin_Tour/CreateTour";
import GetTour from "../components/Admin/Admin_Tour/GetTour";
import AdminLogin from '../pages/AdminLogin';
import CompanyLogin from '../pages/CompanyLogin';
import CompanyRegister from "../pages/CompanyRegister";
import CreateTourSchedule from "../components/Admin/Admin_Tour/CreateTourschedule";
import TourGallery from "../components/Admin/Admin_Tour/TourGallery";
import CreateTouroffer from "../components/Admin/Admin_Tour/CreateTouroffer";
import Mediia from "../pages/Mediia";
import Noteligible from "../pages/Noteligible";
import CreateFAQS from "../components/Admin/Admin_Tour/CreateFAQS";
import Logins from "../pages/Logins";
import Cities from "../pages/Cities";
import CityTours from "../pages/CityTours";
import Companies from "../pages/Companies";
import CompanyTours from '../pages/CompanyTours';
import Signup from "../pages/Signup";
import GetToursDetails from "../components/Admin/Admin_Tour/GetToursDetails"; // <-- Add this import
import CompanyDashboard from "../components/Company/CompanyDashboard";
import UpdateTourSchedule from "../components/Admin/Admin_Tour/UpdateTourSchedule";

const Routers = () => {
  return (
    <HelmetProvider>
    <Routes>
      {/* Home */}
      <Route path="/home" element={<Home />} />
      <Route path="/cities" element={<Cities />} />
      <Route path="/cities/tours/:cityName" element={<CityTours />} />

      {/* Authentication */}
      <Route path="/signup" element={<Signup />} />
      <Route path="/" element={<Home />} />
      <Route path="/user/login" element={<Login />} />
      <Route path="/login" element={<Logins />} />
      <Route path="/user/register" element={<UserRegister />} />
      <Route path="/company/login" element={<CompanyLogin />} />
      <Route path="/company/register" element={<CompanyRegister />} />
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="/not-eligible" element={<Noteligible />} />

      {/* Tours */}
      <Route path="/thank-you" element={<Thankyou />} />
      <Route path="/tours" element={<Tours />} />
      <Route path="/tours/:id" element={<TourDetails />} />
      <Route path="/tours/gallery" element={<TourGallery />} />

      {/* Companies */}
      <Route path="/companies" element={<Companies />} />
      <Route path="/companies/:id/tours" element={<CompanyTours />} />

      <Route path="/company/dashboard" element={<CompanyDashboard />} />
      

      {/* Admin Dashboard */}
      <Route path="/admin/dashboard" element={<Dashboard />} />
      <Route path="/admin/dashboard/create/tour" element={<CreateTour />} />
      <Route path="/admin/dashboard/get/tour" element={<GetTour />} />
      <Route path="/admin/dashboard/create/tour/schedule" element={<CreateTourSchedule />} />
      <Route path="/admin/dashboard/create/tour/offer" element={<CreateTouroffer />} />
      <Route path="/admin/dashboard/create/tour/media" element={<Mediia />} />
      <Route path="/admin/dashboard/create/tour/faqs" element={<CreateFAQS />} />
      <Route path="/update/tour/schedule/:id" element={<UpdateTourSchedule />} />
     
      
      {/* New route for GetToursDetails */}
      <Route path="/admin/dashboard/get/tour/details/:id" element={<GetToursDetails />} />

    </Routes>
    </HelmetProvider>
  );
};

export default Routers;
