import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../Admincss/GetTourDetails.css';
import { useNavigate, useParams } from 'react-router-dom';
import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import UpdateTour from './Updatetour';
import { Port } from '../../../Port';
import CircleComponent from '../../../components/CircleComponent'; // Import CircleComponent

const GetToursDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [tourSchedules, setTourSchedules] = useState([]);
    const [show, setShow] = useState(false); // Manage modal visibility

    const handleToggle = () => setShow(!show);
   
    useEffect(() => {
        const fetchTourSchedules = async () => {
            try {
                const response = await axios.get(`${Port}/tour/schedule/get`);
                const filteredSchedules = response.data.filter(schedule => schedule._id === id);
                setTourSchedules(filteredSchedules);
            } catch (error) {
                console.error('Error fetching tour schedules', error);
            }
        };
        fetchTourSchedules();
    }, []);

    const UpdateTourSchedule = () => {
        navigate(`/update/tour/schedule/${id}`);
    };

    return (
        <div className="get-tour-container">
            {tourSchedules && tourSchedules.length > 0 && tourSchedules.map(scheduleGroup => (
                <div key={scheduleGroup._id} className="tour-schedule">
                    <div className="tour-schedule-content">
                        {/* Left Section - Tour Images and Details */}
                        <div className="tour-details">
                            <div className="tour-images">
                                {scheduleGroup.tourid.images && scheduleGroup.tourid.images.length > 0 ? (
                                    scheduleGroup.tourid.images.map((image, index) => (
                                        <img
                                            key={index}
                                            src={`${image}`}
                                            alt={`Tour ${scheduleGroup.tourid.title} Image ${index}`}
                                            className="tour-image"
                                        />
                                    ))
                                ) : (
                                    <p>No images available for this tour.</p>
                                )}
                            </div>
                            <div className="details-content">
                                <h4>Tour Details:</h4>
                                <p><strong>Title:</strong> {scheduleGroup.tourid.title}</p>
                                <p><strong>From City:</strong> {scheduleGroup.tourid.fromcity}</p>
                                <p><strong>To City:</strong> {scheduleGroup.tourid.tocity}</p>
                                <p><strong>From Date:</strong> {scheduleGroup.tourid.fromdate.slice(0, 10)}</p>
                                <p><strong>To Date:</strong> {scheduleGroup.tourid.todate.slice(0, 10)}</p>
                                <p><strong>Time:</strong> {scheduleGroup.tourid.time}</p>
                                <p><strong>Duration:</strong> {scheduleGroup.tourid.duration}</p>
                                <p><strong>Price:</strong> {scheduleGroup.tourid.price}</p>
                                {scheduleGroup.tourid.category.map((val, index) => (
                                    <p key={index}><strong>Category:</strong> {val.title}</p>
                                ))}
                                <p><strong>Description:</strong> {scheduleGroup.tourid.description}</p>
                                <button className="btn btn-sm bookings__btn" onClick={handleToggle}>Update Tour</button>
                            </div>
                        </div>

                        {/* Right Section - Schedule Details */}
                        <div className="schedule-details">
                            <CircleComponent tourscheduleid={scheduleGroup._id} /> {/* Display circle path */}
                            <button className="update-button my-2" onClick={UpdateTourSchedule}>Update Tour Schedule</button>
                        </div>
                    </div>
                </div>
            ))}

            {/* Modal Component */}
            <Modal isOpen={show} toggle={handleToggle} centered className='h-75'>
                <ModalHeader toggle={handleToggle}>Update Tour</ModalHeader>
                <ModalBody>
                    <UpdateTour tourdata={tourSchedules} close={handleToggle} />
                </ModalBody>
            </Modal>
        </div>
    );
};

export default GetToursDetails;
