import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import "../../shared/TourCard.css";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Tourdetailid } from "../Redux Toolkit/authSlice";
import { Port } from "../../Port";
const FeaturedTourList = () => {
  const dispatch = useDispatch();
  const [tourData, setTourData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchToursAndSchedules();
  }, []);

  const fetchToursAndSchedules = async () => {
    try {
      const tourResponse = await axios.get(
        `${Port}/tour/get`
      );

      setTourData(tourResponse.data); // Set the fetched tours data
    } catch (err) {
      console.error("Error fetching tours and schedules:", err);
      toast.error("Error fetching tours and schedules");
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading)   return <div className="d-flex justify-content-center align-items-center vh-100 fw-bolder text-warning">
  Loading...
</div>
  if (error) return <div>{error}</div>;

 
  
    return (
      <div className="tour__card container">
  
      <div className="row">
        {tourData.map((tour, index) =>
          tour.availableseats === 0 ? null : (
            <div key={index} className="col-lg-3 col-md-6 col-sm-12 mb-4">
         
              <Card className="d-flex h-100">
                {/* Wrap the card content in a Link */}
                <Link
                  to={`/tours/${tour.slug}`}
                  onClick={() => dispatch(Tourdetailid(tour._id))}
                  className="card-link-wrapper"
                >
                  {/* Image Section */}
                  {tour.images && tour.images.length > 0 && (
                    <div>
                      <span className="tour__img">
                        <img
                          src={`${tour.images[0]}`}
                           alt={`Image ${tour.images[0]}`}
                          className="tour__image"
                        />
                      </span>
                    </div>
                  )}
                  <CardBody className="d-flex flex-column justify-content-between">
                    <div className="card__top d-flex align-items-center justify-content-between">
                      <span className="tour__location d-flex align-items-center gap-1">
                        <i className="ri-map-pin-line"></i>
                        <span className="truncate-text">{`${tour.fromcity} to ${tour.tocity}`}</span>
                      </span>
                       <span className="tour__rating d-flex align-items-center gap-1">
  {tour.avgRating === 0 ? (
    <span>Not Rated</span>
  ) : (
    <div className="star-rating">
      {[...Array(5)].map((_, index) => (
        <i
          key={index}
          className={`ri-star-fill ${index < tour.avgRating ? "filled" : ""}`}
        ></i>
      ))}
    </div>
  )}
</span>

                    </div>
                    <h5 className="tour__title">
                      {tour.title.length > 31 ? (
                        `${tour.title.slice(0, 28)}...`
                      ) : (
                        tour.title
                      )}
                    </h5>
    
                    {/* Date and Time Section */}
                    <div className="tour__date-time d-flex justify-content-between align-items-center gap-3 mt-2">
                      <span className="tour__date d-flex align-items-center gap-1">
                        <i className="ri-calendar-line"></i>
                        <span>
                          {new Date(tour.fromdate).toLocaleDateString()}
                        </span>
                      </span>
                      <span className="tour__time d-flex align-items-center gap-1">
                        <i className="ri-time-line"></i>
                        <span>
                          {tour.time
                            ? new Date(
                                `${new Date().toISOString().split("T")[0]}T${
                                  tour.time
                                }`
                              ).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })
                            : "Time not available"}
                        </span>
                      </span>
                    </div>
    
                    <div className="card__bottom d-flex justify-content-between align-items-center justify-content-between mt-3">
      <h5>
        {tour.price}<span className="small-currency">Rs</span><span>/person</span>
      </h5>
      <button className="btn btn-sm bookings__btn">
        <Link
          to={`/tours/${tour.slug}`}
          onClick={() => dispatch(Tourdetailid(tour._id))}
        >
          Book Now
        </Link>
      </button>
    </div>
                  </CardBody>
                </Link>
    
              </Card>
            </div>
          )
        )}
      </div>
    </div>
    
    );
    
};

export default FeaturedTourList;
