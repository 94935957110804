import React, { useState, useEffect } from "react";
import "./App.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Layout from "./components/Layout/Layout";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading for 3 seconds
    setTimeout(() => {
      setLoading(false);
    }, 3000); // Set loading state to false after 3 seconds
  }, []);

  return (
    <div>
      {/* Loader shown when loading is true */}
      {loading ? (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      ) : (
        <Layout />
      )}
    </div>
  );
}

export default App;
