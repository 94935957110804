import React, { useState, useEffect } from "react";
import "../styles/tour-details.css";
import { Container, Row, Col } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { setTourid } from "../components/Redux Toolkit/authSlice";
import Booking from "../components/Booking/Booking";
import Newsletter from "../shared/Newsletter";
import CircleComponent from "../components/CircleComponent";
import Review from "../shared/Review";
import { Port } from "../Port";
import SEO from "../SEO";

const TourDetails = () => {
  const tourdetailid = useSelector((state) => state.auth.tourdetailid);
  const dispatch = useDispatch();
  const [tourData, setTourData] = useState(null);
  const [tourScheduleId, setTourScheduleId]=useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchToursAndSchedules = async () => {
      try {
        const getAllTourSchedule = await axios.get(`${Port}/tour/schedule/get`);
        // Find the matching tour schedule and set the ID
        const matchingTour = getAllTourSchedule.data.find(
          (tours) => tours.tourid && tours.tourid._id === tourdetailid
        );
  
        if (matchingTour) {
          setTourScheduleId(matchingTour._id);
  
          // Directly fetch the tour data using the matching tour schedule ID
          const response = await axios.get(`${Port}/tour/schedule/get/${matchingTour._id}`);
          setTourData(response.data);
        }
      } catch (err) {
        console.error("Error fetching tours and schedules:", err);
        toast.error("Error fetching tours and schedules");
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchToursAndSchedules();
  
    if (tourData?._id) {
      dispatch(setTourid(tourData._id));
    }
  }, [dispatch, tourData?._id, tourScheduleId, tourdetailid]);
  
  if (loading)  return <div className="d-flex justify-content-center align-items-center vh-100 fw-bolder text-warning">
  Loading...
</div>
  if (error) return <div>{error}</div>;

  const { tourid } = tourData || {};

  // Function to format time to 12-hour format
  const formatTimeTo12Hour = (time24) => {
    const [hour, minute] = time24.split(":");
    const hourInt = parseInt(hour, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    const hour12 = hourInt % 12 || 12; // Convert hour to 12-hour format
    return `${hour12}:${minute} ${period}`;
  };

  return (
    <div className="container-fluid">
      {/* SEO */}
      <SEO
        title={`${tourid?.title}`}
        description={`${tourid?.description}`}
        keywords={tourid?.tags?.join(", ") || "travel, tours, trips, vacations, adventure, sightseeing, holiday packages, TripWaly"}
        url="tour/detail"
      />

      <ToastContainer />
      <section>
        <Container>
          <Row>
            <Col lg="8">
              <div className="tour__content">
                {tourid?.images?.length > 0 && (
                  <img
                    src={`${tourid.images[0]}`}
                    alt={tourid.images[0]}
                    className="tour-image"
                  />
                )}

                {/* Tour Details Card */}
               
                <div className="tour-details-card my-4">
                
                  <h3 className="tour-details-heading">{tourid.tocity} Tour Details</h3>
                  <div className="tour-details-content">
                  <p>
                      Description: <strong>{tourid.description}</strong>
                    </p>
                    <p>
                      To City: <strong className="text-bold">{tourid.fromcity}</strong>
                    </p>
                    <p>
                      Tour Duration: <strong>{tourid.duration}</strong>
                    </p>
                    <div className="d-flex checking">
                    <p>
                        Departure Time: <strong>{formatTimeTo12Hour(tourid.time)}</strong>
                      </p>
                      <p>
                        From date: <strong>{tourid.fromdate.slice(0, 10)}</strong>
                      </p>
                      <p>
                        To date: <strong>{tourid.todate.slice(0, 10)}</strong>
                      </p>
                      
                    </div>
                    <p>
                      Available seats: <strong>{tourid.availableseats}</strong>
                    </p>
                    <p>
                      Price: <strong>{tourid.price} Rs</strong>{" "}
                      <span className="text-warning">per person</span>
                    </p>
                    
                  </div>
                </div>

                <CircleComponent tourscheduleid={tourData._id} />

                {/* Booking component for small screens above Review */}
                <Col xs="12" lg="12" className="d-block d-lg-none">
                  <Booking
                    tourscheduleid={tourData._id}
                    tourid={tourid._id}
                    tour={tourid}
                  />
                </Col>

                <Review tourscheduleid={tourid._id} />
              </div>
            </Col>

            <Col lg="4" className="d-none d-lg-block">
              {/* Booking component for large screens */}
              <Booking
                tourscheduleid={tourData._id}
                tourid={tourid._id}
                tour={tourid}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <Newsletter />
    </div>
  );
};

// Error Boundary Functional Component
const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleError = (error) => {
      console.error("Error caught in Error Boundary:", error);
      setHasError(true);
    };
    window.addEventListener("error", handleError);

    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);

  if (hasError) {
    return <h1>Something went wrong.</h1>;
  }

  return children;
};

// Wrap TourDetails component with ErrorBoundary
const TourDetailsWithErrorBoundary = () => (
  <ErrorBoundary>
    <TourDetails />
  </ErrorBoundary>
);

export default TourDetailsWithErrorBoundary;
