import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "../Admincss/tourSchedule.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload,faTrash,faPlus } from '@fortawesome/free-solid-svg-icons';
import { Port } from '../../../Port';
import SEO from '../../../SEO';
const CreateTourSchedule = () => {
  const Logindetail=useSelector((state)=>state.auth.login.role);
  const navigate=useNavigate();
  const tourId=useSelector((state)=>state.auth.tourid);
  const [tourid, setTourid] = useState(tourId);
  const [imageName, setImageName] = useState("");
  const [schedules, setSchedules] = useState([
    { title: '', description: '', time: '', city: '', date:'' },
  ]);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleScheduleChange = (index, event) => {
    const { name, value, files } = event.target;
    const newSchedules = [...schedules];
 {
      newSchedules[index][name] = value;
    }

    setSchedules(newSchedules);
  };

  const handleAddSchedule = () => {
    setSchedules([...schedules, { title: '', description: '', time: '', city: '',date:'' }]);
  };

  const handleRemoveSchedule = () => {
    if (schedules.length > 1) {
      setSchedules(schedules.slice(0, -1)); 
    }
  };

  const handleImageChanges = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageName(file.name);
      setSelectedFile(file); // Update selected file
    }
  };
  const handleRemoveImage = () => {
    setImageName(null);
    document.getElementById("customFileInput").value = ""; // Reset file input
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('tourid', tourid);
  
    // Append the selected file
    if (selectedFile) {
      formData.append('images', selectedFile);
    }
  
    schedules.forEach((schedule, index) => {
      formData.append(`schedules[${index}][title]`, schedule.title);
      formData.append(`schedules[${index}][description]`, schedule.description);
      formData.append(`schedules[${index}][time]`, schedule.time);
      formData.append(`schedules[${index}][date]`, schedule.date);
      formData.append(`schedules[${index}][city]`, schedule.city);
   });
    formData.append('schedules', JSON.stringify(schedules));
    try {
       await axios.post(`${Port}/tour/schedule/create`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      {Logindetail === "admin" ? navigate("/admin/dashboard") : navigate("/company/dashboard");}
    } catch (error) {
      alert('Error creating tour schedule:', error.response.data);
      console.log(error)
    }
  };
  
  const fileChangeHandler = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  
  return (

    <form onSubmit={handleSubmit} className='container' encType="multipart/form-data">
  {/* SEO */}
  <SEO
        title="Create Tour Schedule-Tripwaly"
        description="Plan and set your tour schedule with Tripwaly. Define tour dates, timings, locations, and itinerary details to offer a seamless travel experience for your customers."
        keywords="create tour schedule, Tripwaly schedule, tour planning, set tour dates, tour itinerary, travel schedule, tour timings, travel dates, manage tour schedule, Tripwaly tour planning, tour booking, travel calendar"
      url="create/tour/schedule"
      />
   
      {schedules.map((schedule, index) => (
  <div key={index} className="mb-4">
    <h3>Schedule {index + 1}</h3>
    
    <div className="row">
      <div className="col-md-6">
        <label>Title:</label>
        <input
          name="title"
          value={schedule.title}
          className="form-control shadow-sm p-2"
          onChange={(e) => handleScheduleChange(index, e)}
          required
        />
      </div>
      <div className="col-md-6 mb-3">
      <label>Description</label>
            <textarea
          name="description"
          value={schedule.description}
          className="form-control shadow-sm p-2"
          onChange={(e) => handleScheduleChange(index, e)}
          required
          />
             </div>
    </div>

    <div className="row">
      <div className="col-md-6">
        <label>Time:</label>
        <input
          type="time"
          name="time"
          value={schedule.time}
          className="form-control shadow-sm p-2"
          onChange={(e) => handleScheduleChange(index, e)}
          required
        />
      </div>
      <div className="col-md-6">
        <label>Date:</label>
        <input
          type="date"
          name="date"
          value={schedule.date}
          className="form-control shadow-sm p-2"
          onChange={(e) => handleScheduleChange(index, e)}
          required
        />
      </div>
    </div>

    <div className="row">
      <div className="col-md-6">
        <label>City:</label>
        <input
          name="city"
          value={schedule.city}
          className="form-control shadow-sm p-2"
          onChange={(e) => handleScheduleChange(index, e)}
          required
        />
      </div>
      <div className="col-md-6">
      <div className="form-group mt-4">
  <label htmlFor="customFileInput" className="custom-file-label d-flex align-items-center">
    <FontAwesomeIcon icon={faUpload} className="upload-icon me-2" />
    <span>{imageName ? "Change Image" : "Upload Image"}</span>
  </label>
  <input
    required
    type="file"
    id="customFileInput"
    accept="image/*"
    onChange={handleImageChanges}
    className="custom-file-input"
  />
  {imageName && (
    <div className="image-preview my-3 d-flex align-items-center justify-content-between">
      <span className="image-name text-secondary">{imageName}</span>
      <button
        type="button"
        className="btn btn-outline-danger btn-sm"
        onClick={handleRemoveImage}
        aria-label="Remove Image"
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>
    </div>
  )}
</div>
</div>

    </div>
  </div>
))}

<div className="d-flex align-items-center">
  {/* Add Schedule Button */}
  <button
    type="button"
    className="btn btn-seconday d-flex align-items-center px-4 py-2 rounded-pill shadow-sm"
    onClick={handleAddSchedule}
  >
    <FontAwesomeIcon icon={faPlus} className="me-2" />
    Add
  </button>

  {/* Remove Button */}
  {schedules.length > 1 && (
    <button
      type="button"
      className="btn btn-danger d-flex align-items-center px-4 py-2 rounded-pill shadow-sm ms-2"
      onClick={handleRemoveSchedule}
    >
      <FontAwesomeIcon icon={faTrash} className="me-2" />
      Remove
    </button>
  )}
</div>

      <div className="submit">
      <button type="submit" className="btn btn-primary mt-2 mx-2">Create Schedule</button>
      </div>
    </form>
  );
};

export default CreateTourSchedule;
