import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Tooltip } from 'reactstrap';
import "../styles/circleComponent.css";
import { Port } from '../Port';

const CircleComponent = ({ tourscheduleid }) => {
  const [tourData, setTourData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hoveredCircleId, setHoveredCircleId] = useState(null);

  const pathRef = useRef(null);
  useEffect(() => {
    const fetchToursAndSchedules = async () => {
      try {
        const response = await axios.get(`${Port}/tour/schedule/get/${tourscheduleid}`);
        setTourData(response.data);
      } catch (err) {
        console.error('Error fetching tours and schedules:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchToursAndSchedules();
  }, [tourscheduleid]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const generatePath = (points) => {
    if (points.length <= 1) return "";

    let path = `M ${points[0].x} ${points[0].y} `;
    for (let i = 1; i < points.length; i++) {
      const prevPoint = points[i - 1];
      const currPoint = points[i];
      path += `C ${(prevPoint.x + currPoint.x) / 2} ${prevPoint.y}, ${(prevPoint.x + currPoint.x) / 2} ${currPoint.y}, ${currPoint.x} ${currPoint.y} `;
    }
    return path.trim();
  };

  const calculateCirclePositions = (count) => {
    if (count <= 1) return [{ x: 50, y: 50 }]; // Center if only one circle

    const positions = [];
    const stepX = 80 / (count - 1 || 1); // Spread circles evenly across the X-axis
    const offsetY = 20; // Vertical offset for alternating positions

    for (let i = 0; i < count; i++) {
      const x = 10 + stepX * i;
      const y = i % 2 === 0 ? 30 : 70; // Alternate Y position for zig-zag path
      positions.push({ x, y });
    }

    return positions;
  };

  const circlePositions = calculateCirclePositions(tourData.schedules.length);
  const pathData = generatePath(circlePositions);

  return (
    <div className="cardd-container">
      <div className="tour-road-container">
        <svg className="s-road" viewBox="0 0 100 100" preserveAspectRatio="none">
          {pathData && (
            <path
              ref={pathRef}
              d={pathData}
              stroke="#333"
              strokeWidth="4"
              fill="transparent"
              id="tour-path"
            />
          )}
        </svg>

        {tourData.schedules.map((schedule, index) => {
          const point = circlePositions[index];

          return (
            <div
              className="circle"
              key={schedule._id}
              style={{
                top: `${point.y}%`,
                left: `${point.x}%`,
                transform: "translate(-50%, -50%)",
              }}
              onMouseEnter={() => setHoveredCircleId(schedule._id)}
              onMouseLeave={() => setHoveredCircleId(null)}
              id={`circle-${schedule._id}`}
            >
              <img src={`${schedule.images}`} alt={schedule.city} />
              <div className="circle-content">
                <h3>{schedule.city}</h3>
                <h3>{schedule.time}</h3>
              </div>

              <Tooltip
                isOpen={hoveredCircleId === schedule._id}
                target={`circle-${schedule._id}`}
                className="custom-tooltip"
                placement="right"
              >
                <h4>{schedule.city}</h4>
                <div className="d-flex justify-content-around">
                  <p>Date: {schedule.date}</p>
                  <p>Time: {schedule.time}</p>
                </div>
                {schedule.description && <p>{schedule.description}</p>}
              </Tooltip>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CircleComponent;
