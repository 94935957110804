// src/pages/Signup.jsx
import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import "../styles/signup.css"; 
import SEO from "../SEO";

const Logins = () => {
  const navigate = useNavigate();

  // Handlers for navigating to the respective registration pages
  const handleUserSignup = () => {
    navigate("/user/login");
  };

  const handleCompanySignup = () => {
    navigate("/company/login");
  };

  return (
    <section className="signup-page">
       <SEO
        title="User or Company Login-Tripwaly"
        description="Login to Tripwaly for a seamless experience. Access exclusive tours, travel packages, and services as a user or a company. Discover amazing travel destinations with Tripwaly"
        keywords="Tripwaly login, user login, company login, tour login, travel packages, explore tours, register on Tripwaly, Tripwaly Tuesday login, travel services, tourism, vacation packages"
      url="login"
      />
   

      <Container>
        <Row>
          <Col lg="12" className="text-center">
            <h1 className="mb-4">Welcome to Login Page</h1>
            <p className="lead">
              Please select whether you want to Login as a user or as a company.
            </p>
            <div className="d-flex justify-content-center gap-4 mt-5">
              <Button
                className="btn btn-primary"
                size="lg"
                onClick={handleUserSignup}
              >
                User Login 
              </Button>

              <Button
                className="btn btn-secondary"
                size="lg"
                onClick={handleCompanySignup}
              >
                Company Login
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Logins;
